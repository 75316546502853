.alerts--container {
  position: fixed;
  top: 1em;
  left: 20em;
  width: calc(100% - 40em);
  z-index: 99999;
}

.banner--shadow {
  box-shadow: var(--color-dark-black-shadow);
}
